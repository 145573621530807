<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
		class="instrument-drawer-wrapper"
	>
		<Spin fix v-if="loading"></Spin>
		<p class="warning">*值为空时请用N/A代替</p>
		<Form
			:model="formData"
			ref="instrumentForm"
			label-position="top"
			:rules="rulesValid"
		>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="仪器名称" prop="equipmentName">
						<Input
							v-model="formData.equipmentName"
							placeholder="请输入仪器名称"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="编号（中心）" prop="equipmentNo">
						<Input
							v-model="formData.equipmentNo"
							placeholder="请输入编号（中心）"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="安装位置" prop="installSite">
						<Input
							v-model="formData.installSite"
							placeholder="请输入安装位置"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="设备状态" prop="equipmentStatus">
						<Input
							v-model="formData.equipmentStatus"
							placeholder="请输入设备状态"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem
						label="校验/维护/验证日期（日期格式：2020.06.03）"
						prop="checkTime"
					>
						<Input
							v-model="formData.checkTime"
							@on-blur="handleBlur"
							placeholder="校验/维护/验证日期"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="校验时限" prop="checkFrequencyVal">
						<Select
							v-model="formData.checkFrequencyVal"
							:label-in-value="true"
							@on-change="handleChangeCheck"
						>
							<Option value="N/A">N/A</Option>
							<Option value="0.5">6个月/次</Option>
							<Option value="1">1年/次</Option>
							<Option value="2">2年/次</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="下次校验/维护/验证日期" required>
						<Input
							:class="['date-disabled', { 'warn-disabled': isWarn }]"
							v-model="formData.nextCheckTime"
							disabled
							placeholder="下次校验/维护/验证日期"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="检定/校验" prop="type">
						<Input v-model="formData.type" placeholder="请输入检定/校验" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="序列号(SN）" prop="serialNumber">
						<Input
							v-model="formData.serialNumber"
							placeholder="请输入序列号(SN）"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="生产厂商" prop="manufacturer">
						<Input
							v-model="formData.manufacturer"
							placeholder="请输入生产厂商"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="型号" prop="model">
						<Input v-model="formData.model" placeholder="请输入型号" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="启用时间" prop="startTime">
						<Input v-model="formData.startTime" placeholder="请输入启用时间" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="停用时间" prop="stopTime">
						<Input v-model="formData.stopTime" placeholder="请输入停用时间" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="信息更换" prop="informationChange">
						<Input
							v-model="formData.informationChange"
							placeholder="请输入信息更换"
						/>
					</FormItem>
				</Col>
			</Row>
			<Row style="padding-bottom: 10px" v-if="visible">
				<Col span="24">
					<FormItem label="上传文件" prop="fileList">
						<!--accept=".pdf,.xlsx,.xls,.doc,.docx"-->
						<Upload
							action="/"
							ref="upload"
							multiple
							:before-upload="file => handleBeforeUpload(file)"
							:on-error="onError"
							:on-remove="onRemove"
							:on-preview="handlePreview"
							:default-file-list="formData.fileList"
						>
							<Button :loading="uploadLoading">{{
								uploadLoading ? "上传中..." : "上传附件"
							}}</Button>
						</Upload>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/instrument/instrument"
import moment from "moment"
import { formDataToMD5 } from "@/utils/util"

const { apiUpdate, apiAdd, apiGet, apiUpload, apiDelFiles, apiGetFiles } = api

export default {
	name: "Add",
	props: ["visible", "id"],
	data() {
		return {
			loading: false,
			uploadLoading: false,
			formData: {
				checkFrequencyVal: "",
				checkFrequency: "",
				fileList: []
			},
			fileIds: [],
			title: "",
			isWarn: false,
			rulesValid: {
				equipmentName: {
					required: true,
					message: "仪器名称不能为空",
					trigger: "blur"
				},
				equipmentNo: {
					required: true,
					message: "编号（中心）不能为空",
					trigger: "blur"
				},
				installSite: {
					required: true,
					message: "安装位置不能为空",
					trigger: "blur"
				},
				equipmentStatus: {
					required: true,
					message: "设备状态不能为空",
					trigger: "blur"
				},
				checkTime: {
					required: true,
					message: "校验/维护/验证日期不能为空",
					trigger: "blur"
				},
				checkFrequencyVal: {
					required: true,
					message: "校验时限不能为空",
					trigger: "change blur"
				},
				type: {
					required: true,
					message: "检定/校验不能为空",
					trigger: "blur"
				},
				serialNumber: {
					required: true,
					message: "序列号(SN）不能为空",
					trigger: "blur"
				},
				manufacturer: {
					required: true,
					message: "生产厂商不能为空",
					trigger: "blur"
				},
				model: {
					required: true,
					message: "型号不能为空",
					trigger: "blur"
				},
				startTime: {
					required: true,
					message: "启用时间不能为空",
					trigger: "blur"
				},
				stopTime: {
					required: true,
					message: "停用时间不能为空",
					trigger: "blur"
				},
				informationChange: {
					required: true,
					message: "信息更换不能为空",
					trigger: "blur"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.instrumentForm.resetFields()
				this.formData = {
					checkFrequencyVal: "",
					checkFrequency: "",
					fileList: []
				}
				this.fileIds = []
				this.title = "新增仪器设备记录"
				if (this.id) {
					this.title = "修改仪器设备记录"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({
							id: this.id
						})
						if (res) {
							this.formData = {
								...this.formData,
								...res.data
							}
							this.fileIds = res.data.fileIds ? res.data.fileIds.split(",") : []
							this.getFileList(this.id)
							if (this.formData.checkFrequency) {
								this.formData.checkFrequencyVal = this.getCheckFrequency(
									this.formData.checkFrequency
								)
								this.$nextTick(() => {
									this.getNextCheckTime(
										this.formData.checkTime,
										this.formData.checkFrequencyVal
									)
								})
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		getCheckFrequency(label) {
			let val = ""
			switch (label) {
				case "N/A":
					val = "N/A"
					break
				case "6个月/次":
					val = "0.5"
					break
				case "1年/次":
					val = "1"
					break
				case "2年/次":
					val = "2"
					break
				default:
					val = "N/A"
					break
			}
			return val
		},
		// 校验/维护/验证日期（日期格式：2020.06.03）
		handleBlur(e) {
			const val = e.target.value
			this.getNextCheckTime(val, this.formData.checkFrequencyVal)
		},
		// 校验时限
		handleChangeCheck(val) {
			if (val) {
				this.formData.checkFrequencyVal = val.value
				this.formData.checkFrequency = val.label
				this.getNextCheckTime(this.formData.checkTime, val.value)
			}
		},
		// 获取下次校验 维护 验证日期
		getNextCheckTime(checkTime, checkFrequencyVal) {
			let date = "N/A"
			if (checkTime && checkFrequencyVal) {
				if (checkFrequencyVal === "N/A" || checkTime === "N/A") {
					this.isWarn = true
					date = "N/A"
				} else {
					let arr = checkTime.split(".")
					arr = arr.map((item, index) => {
						// 月份是0-11，需要减1来计算。
						if (index === 1) {
							// return Number(item.trim()) - 1;
						}
						return Number(item.trim())
					})
					if (arr.length === 3 && arr[2] !== 0) {
						this.isWarn = false
						const arrDate = new Date(arr.join("-"))
						if (checkFrequencyVal === "0.5") {
							// 6个月/次
							date = moment(arrDate).add(6, "months").format("YYYY.MM.DD")
						} else {
							// 1年/次  2年/次
							date = moment(arrDate)
								.add(Number(checkFrequencyVal), "years")
								.format("YYYY.MM.DD")
						}
					} else {
						this.isWarn = true
						date = "N/A"
					}
				}
				if (date === "Invalid date") {
					this.isWarn = true
					date = "校验/维护/验证日期，填写有误，请检查后更正"
				}
				this.$nextTick(() => {
					this.$set(this.formData, "nextCheckTime", date)
				})
			}
		},
		// 获取当前设备下的附件
		getFileList(id) {
			this.$asyncDo(async () => {
				const res = await apiGetFiles(id)
				if (res) {
					this.formData.fileList = res.data ? [...res.data] : []
				}
			})
		},
		handleBeforeUpload(file) {
			// .pdf,.xlsx,.xls,.doc,.docx
			// if (!/\.(pdf|xlsx|xls|doc|docx)$/.test(file.name)) {
			//   this.$Message.error('只能上传后缀名为pdf,xlsx,xls,doc,docx格式的文件');
			//   return false;
			// }
			this.uploadLoading = true
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, md5Str)
			})
			return false
		},
		// 上传文件
		uploadFile(file, md5Str) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				fd.append("md5", md5Str)
				const res = await apiUpload(fd)
				if (res) {
					this.$Message.success("上传成功!")
					const newFile = Object.assign(file, { id: res.data })
					this.formData.fileList.push(newFile)
					this.fileIds.push(res.data)
				}
				this.uploadLoading = false
			})
		},
		onError(error) {
			this.$Message.error(error)
		},
		onRemove(file) {
			this.formData.fileList = this.formData.fileList.filter(item => {
				if (file.id) {
					return item.id !== file.id
				}
				return item.uid !== file.uid
			})
			this.fileIds = this.fileIds.filter(item => item !== file.id)
		},
		// 下载
		handlePreview(file) {
			window.open(
				`${this.$baseUrl}/document/attachment/inline/${file.id}?token=${this.$store.state.user.token}`
			)
		},
		onCancel() {
			this.$emit("onCancel")
		},
		async onOk() {
			const valid = await this.$refs.instrumentForm.validate()

			if (valid) {
				this.loading = true
				let res = null
				const params = {
					...this.formData,
					fileIds:
						this.fileIds && this.fileIds.length ? this.fileIds.join(",") : ""
				}
				if (this.id) {
					res = await apiUpdate({
						...params,
						id: this.id
					})
				} else {
					res = await apiAdd(params)
				}
				if (res) {
					this.$emit("onOk")
					this.$emit("onCancel")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			}
			return false
		}
	}
}
</script>

<style lang="less">
.instrument-drawer-wrapper {
	.warning {
		color: #ed4014;
		font-size: 12px;
		margin-bottom: 10px;
	}
	.date-disabled {
		.ivu-input[disabled] {
			color: #515a6e;
		}
	}
	.warn-disabled {
		.ivu-input[disabled] {
			color: #ed4014;
		}
	}
}
</style>
