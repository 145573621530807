<template>
  <div class="workbench-wrap">
    <div class="charts-top">
      <div class="my-chart-wrap my-chart-wrap1">
        <h3 class="title"><span></span>项目类型统计</h3>
        <div ref="myChartA" class="my-chart-content">
          <div class="nodata">
            <span>暂无数据</span>
          </div>
        </div>
      </div>
      <div class="my-chart-wrap my-chart-wrap2">
        <h3 class="title"><span></span>项目进度统计</h3>
        <div ref="myChartB" class="my-chart-content">
          <div class="nodata">
            <span>暂无数据</span>
          </div>
        </div>
      </div>
    </div>
    <div class="my-chart-wrap my-chart-wrap3">
      <h3 class="title"><span></span>历年项目总数</h3>
      <div ref="myChartC" class="my-chart-content">
        <div class="nodata">
          <span>暂无数据</span>
        </div>
      </div>
    </div>
    <div class="my-chart-wrap my-chart-wrap4">
      <h3 class="title"><span></span>各科室申请项目统计</h3>
      <div ref="myChartD" class="my-chart-content">
        <div class="nodata">
          <span>暂无数据</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
import reportApi from "@/api/report/report"
import dictionaryApi from "@/api/setting/dictionary"

const {
  apiGetProjectTypeData,
  apiGetProjectProgress,
  apiGetYearData,
  apiAsyncGetDepartData
} = reportApi
const {
  apiAsyncGetData
} = dictionaryApi

export default {
  name: "Workbench",
  components: {},
  data() {
    return {
      chartA: null,
      chartB: null,
      chartC: null,
      chartD: null
    }
  },
  computed: {},
  created() {},
  async mounted() {
    // 项目类型统计
    await this.initChartA()
    // 项目进度统计
    await this.initChartB()
    // 历年项目总数
    await this.initChartC()
    // 各科室申请项目统计
    await this.initChartD()
    window.addEventListener('resize', this.handlerResize)
  },
  destroyed() {
    if (this.chartA) this.chartA = null
    if (this.chartB) this.chartB = null
    if (this.chartC) this.chartC = null
    if (this.chartD) this.chartD = null
  },
  methods: {
    // 处理网页滚动缩放
    handlerResize() {
      if (this.chartA) {
        this.chartA.resize()
      }
      if (this.chartB) {
        this.chartB.resize()
      }
      if (this.chartC) {
        this.chartC.resize()
      }
      if (this.chartD) {
        this.chartD.resize()
      }
    },
    async initChartA() {
      const projectTypeData = await apiGetProjectTypeData()
      console.log("项目类型统计", projectTypeData)
      const keyToName = {
        IIT: '研究者发起的研究（IIT）',
        IST: '申办方发起的研究（IST）'
      }
      const echartData = projectTypeData.map((item) => {
        const { name, value } = item
        return {
          name: keyToName[name],
          value,
        }
      })
      const $el = this.$refs.myChartA
      this.chartA = await echarts.init($el, null, {
        locale: "ZH"
      })
      this.chartA.showLoading()
      this.chartA.setOption({
        title: {
          left: "center"
        },
        color: ["#2D8cF0", "#37BEB7"],
        tooltip: {
          trigger: "item"
        },
        legend: {
          icon: "circle",
          orient: "vertical",
          right: "30",
          top: "0",
          formatter(name) {
            const data = echartData
            const { value } = data.find(item => item.name === name)
            return `${name} ${value}`
          }
        },
        series: [
          {
            name: "项目类型统计",
            type: "pie",
            radius: "50%",
            center: ["35%", "45%"],
            label: {
              show: true
            },
            data: echartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              },
              label: {
                show: true,
                fontSize: 12,
                fontWeight: "bold"
              }
            }
          }
        ]
      })
      this.chartA.hideLoading()
    },
    async initChartB() {
      const resDictionary = await apiAsyncGetData(25)
      const DictionaryData = resDictionary.data
      const data = DictionaryData.leaf.map(item => ({
            value: 0,
            name: item.obj.name,
            status: item.obj.nickname,
          }))
      console.log(data)
      // let data = [
      //   { value: 0, name: "未开始", status: 0 },
      //   { value: 0, name: "研究立项及准备", status: 1 },
      //   { value: 0, name: "研究执行", status: 2 },
      //   { value: 0, name: "研究总结", status: 3 },
      //   { value: 0, name: "项目终止", status: 4 }
      // ]

      const projectProgressData = await apiGetProjectProgress()
      const total = projectProgressData.data.reduce((acc, current) => acc + current.value, 0);
      // 遍历 projectProgressData.data 数组
      projectProgressData.data.forEach((item) => {
        // 根据 projectProgressData.data 中的 name 对应 data 中的 status
        const match = data.find(d => d.status === item.name);
        if (match) {
          match.value = item.value; // 给匹配的对象赋值
        }
      });
      console.log("项目进度统计", projectProgressData.data)
      const $el = this.$refs.myChartB
      this.chartB = await echarts.init($el, null, {
        locale: "ZH"
      })
      this.chartB.showLoading()
      this.chartB.setOption({
        tooltip: {
          trigger: "item"
        },
        color: ["#4A3AFF", "#2D8cF0", "#37BEB7", "#28BC5C", "#FF5C35"],
        legend: {
          icon: "circle",
          orient: "vertical",
          right: "20%",
          top: "20%",
          formatter(name) {
            const { value } = data.find(item => item.name === name)
            return `${name} ${value}`
          }
        },
        series: [
          {
            name: "项目进度统计",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["25%", "45%"],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 0
            },
            label: {
              show: true,
              normal: {
                show: true,
                position: "center",
                color: "#4c4a4a",
                formatter: `{total|${total}}` + "\n\r" + "{active|总项目}",
                rich: {
                  total: {
                    fontSize: 35,
                    fontFamily: "微软雅黑",
                    color: "#454c5c"
                  },
                  active: {
                    fontFamily: "微软雅黑",
                    fontSize: 16,
                    color: "#6c7a89",
                    lineHeight: 30
                  }
                }
              },
              emphasis: {
                // 中间文字显示
                show: true
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 12,
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: true
            },
            data
          }
        ]
      })
      this.chartB.hideLoading()
    },
    async initChartC() {
      const yearData = await apiGetYearData()
      console.log("历年项目报表数据", yearData)
      const $el = this.$refs.myChartC
      this.chartC = await echarts.init($el, null, {
        locale: "ZH"
      })
      this.chartC.showLoading()
      this.chartC.setOption({
        grid: {
          left: "20", // 距离左侧的距离
          right: "20", // 距离右侧的距离
          bottom: "20", // 距离底部的距离
          top: "20", // 距离顶部的距离
          containLabel: true // 包含坐标轴的标签
        },
        xAxis: {
          type: "category",
          data: yearData[0],
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed"
            }
          }
        },
        tooltip: {},
        series: [
          {
            data: yearData[1],
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)"
            },
            barWidth: 25,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#83bff6" },
                { offset: 0.5, color: "#188df0" },
                { offset: 1, color: "#188df0" }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#2378f7" },
                  { offset: 0.7, color: "#2378f7" },
                  { offset: 1, color: "#83bff6" }
                ])
              }
            }
          }
        ]
      })
      this.chartC.hideLoading()
    },
    async initChartD() {
      const departRes = await apiAsyncGetDepartData()
      const [departmentNames, projectCounts] = departRes.data || [[], []]
      console.log("各科室申请项目统计", departRes)
      const $el = this.$refs.myChartD
      this.chartD = await echarts.init($el, null, {
        locale: "ZH"
      })
      this.chartD.showLoading()
      this.chartD.setOption({
        grid: {
          left: "20", // 距离左侧的距离
          right: "20", // 距离右侧的距离
          bottom: "20", // 距离底部的距离
          top: "20", // 距离顶部的距离
          containLabel: true // 包含坐标轴的标签
        },
        xAxis: {
          type: "category",
          data: departmentNames,
          //   [
          //   "消化外科",
          //   "呼吸内科",
          //   "神经内科",
          //   "血液内科",
          //   "肾脏风湿免疫科",
          //   "皮肤科",
          //   "中医科",
          //   "新生儿科",
          //   "儿科",
          //   "临床心理科",
          //   "口腔科",
          //   "普外科",
          //   "乳腺外科",
          //   "心胸外科",
          //   "神经外科",
          //   "泌尿外科",
          //   "骨科"
          // ],
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0, // 横轴信息全部显示
            rotate: 45, // 倾斜度 -90 至 90 默认为0
            margin: 15, // 刻度标签与轴线之间的距离
            textStyle: {
              fontSize: 14, // 横轴字体大小
              color: "#000000" // 颜色
            }
          }
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed"
            }
          }
        },
        tooltip: {},
        series: [
          {
            data: projectCounts,
            //   [
            //   18, 40, 35, 40, 21, 45, 3, 50, 48, 11, 35, 20, 41, 31, 35, 39, 34
            // ],
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)"
            },
            barWidth: 8,
            itemStyle: {
              // 定义常规状态下的样式
              normal: {
                color(params) {
                  // 定义换色数组
                  if (params.dataIndex % 2 === 0) {
                    return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#83bff6" },
                      { offset: 0.5, color: "#188df0" },
                      { offset: 1, color: "#188df0" }
                    ])
                  }
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#48E5E5" },
                    { offset: 0.5, color: "#37BEB7" },
                    { offset: 1, color: "#37BEB7" }
                  ])
                }
              }
            }
          }
        ]
      })
      this.chartD.hideLoading()
    }
  }
}
</script>
<style lang="less">
.workbench-wrap {
  .charts-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
  }

  .my-chart-wrap {
    background: #fff;
    margin-bottom: 16px;
    padding: 10px 20px;

    .title {
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;

      span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        background: #0a84ff;
      }
    }

    .my-chart-content {
      width: 100%;
      height: calc(~"100% - 40px");
    }
  }

  .my-chart-wrap1,
  .my-chart-wrap2 {
    width: calc(~"50% - 8px");
    height: 400px;
  }

  .my-chart-wrap3,
  .my-chart-wrap4 {
    height: 500px;
  }
}
</style>
