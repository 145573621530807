import commonApi from "@/api/api"
import request from "@/utils/request"

const moduleName = "/equipment"

export default {
	...commonApi(moduleName),
	// 上传
	apiUpload: data =>
		request("/equipment/upload-files", { method: "post", body: data }),
	// 删除附件
	apiDelFiles: fileId =>
		request(`/equipment/file/${fileId}`, { method: "delete" }),
	// 获取附件
	apiGetFiles: id => request(`/equipment/${id}/files`, { method: "get" })
}
