<template>
	<div class="content-wrap instrument-wrapper">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="仪器名称">
				<Input
					v-model="searchParams.equipmentName"
					placeholder="请输入仪器名称"
				></Input>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="安装位置">
				<Input
					v-model="searchParams.installSite"
					placeholder="请输入安装位置"
				></Input>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="设备状态">
				<Input
					v-model="searchParams.equipmentStatus"
					placeholder="请输入设备状态"
				></Input>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="下次校验/维护/验证日期">
				<DatePicker
					type="daterange"
					placeholder="请选择日期区间"
					:transfer="true"
					style="width: 100%"
					format="yyyy.MM.dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				v-if="systemActionPermissions.indexOf('btn_equipment_add') > -1"
				@click="handleAddShow"
			>
				新增
			</Button>
			<Upload
				v-if="systemActionPermissions.indexOf('btn_equipment_add') > -1"
				ref="upload"
				:headers="{ token: $store.state.user.token }"
				:show-upload-list="false"
				accept=".xlsx,.xls"
				:before-upload="beforeUpload"
				:on-success="onSuccess"
				:on-error="onError"
				:data="extraData"
				:action="`${$baseUrl}/equipment/import`"
				style="display: inline-block"
			>
				<Button>导入</Button>
			</Upload>
			<Button @click="exportExcel">导出</Button>
			<Button @click="handleDownloadTemplate">下载导入模板</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
			ref="table"
		>
		</CtmsDataGrid>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="initList"
			:id="updateId"
		>
		</Add>
	</div>
</template>
<script>
import { mapState } from "vuex"
import moment from "moment"
import api from "@/api/instrument/instrument"
import { formDataToMD5 } from "@/utils/util"
import Add from "./Add.vue"

const { apiGetPage, apiDelete } = api
export default {
	data() {
		return {
			loading: false,
			importLoading: false,
			times: [],
			searchParams: {
				equipmentName: "",
				installSite: "",
				equipmentStatus: "",
				startTime: this.times ? this.times[0] : "",
				endTime: this.times ? this.times[1] : ""
			},
			oldSearchParams: {
				equipmentName: "",
				installSite: "",
				equipmentStatus: ""
			},
			extraData: {
				md5: ""
			},
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			updateId: "",
			addVisible: false,
			listData: [],
			columns: [
				{
					title: "仪器名称",
					key: "equipmentName",
					minWidth: 160
				},
				{
					title: "编号（中心）",
					key: "equipmentNo",
					minWidth: 160
				},
				{
					title: "安装位置",
					key: "installSite",
					minWidth: 120
				},
				{
					title: "设备状态",
					key: "equipmentStatus",
					minWidth: 100
				},
				{
					title: "校验/维护/验证日期",
					key: "checkTime",
					minWidth: 160
				},
				{
					title: "校验时限",
					key: "checkFrequency",
					minWidth: 100
				},
				{
					title: "下次校验/维护/验证日期",
					key: "nextCheckTime",
					minWidth: 160,
					render: (h, params) => {
						let dtColor = ""
						// 判断是不是日期
						if (
							Number.isNaN(params.row.nextCheckTime) &&
							!Number.isNaN(Date.parse(params.row.nextCheckTime))
						) {
							const nextCheckTime = moment(params.row.nextCheckTime)
							// 超时 一个月内提示
							if (nextCheckTime.isBefore(moment().add(1, "months"))) {
								dtColor = "#ed4014"
							}
						}
						return h(
							"span",
							{
								style: {
									color: dtColor
								}
							},
							params.row.nextCheckTime
						)
					}
				},
				{
					title: "检定/校验",
					key: "type",
					minWidth: 160
				},
				{
					title: "序列号(SN）",
					key: "serialNumber",
					minWidth: 160
				},
				{
					title: "生产厂商",
					key: "manufacturer",
					minWidth: 160
				},
				{
					title: "型号",
					key: "model",
					minWidth: 160
				},
				{
					title: "启用时间",
					key: "startTime",
					minWidth: 160
				},
				{
					title: "停用时间",
					key: "stopTime",
					minWidth: 160
				},
				{
					title: "信息更换",
					key: "informationChange",
					minWidth: 160
				},
				{
					title: "操作",
					key: "action",
					width: 150,
					fixed: "right",
					renderButton: () => {
						const btnList = [
							{
								label: "修改",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "删除",
								on: {
									click: this.handleSwitchState
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							}
						]
						if (
							this.systemActionPermissions.indexOf("btn_equipment_add") === -1
						) {
							btnList[0] = null
							btnList[1] = null
						}
						return btnList.filter(item => !!item)
					}
				}
			]
		}
	},
	components: {
		Add
	},
	computed: {
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 130
			},
			...mapState("permission", ["systemActionPermissions"])
		})
	},
	created() {
		this.initList()
	},
	methods: {
		// 获取数据
		async initList() {
			this.loading = true
			const res = await apiGetPage({
				...this.oldSearchParams,
				pageNum: this.page.current,
				pageSize: this.page.pageSize
			})
			if (res && res.data) {
				this.listData = res.data.list || []
				this.page.total = res.data.total
				// 标红提醒
				const redarr = this.listData.filter(item => {
					if (
						Number.isNaN(item.nextCheckTime) &&
						!Number.isNaN(Date.parse(item.nextCheckTime))
					) {
						const nextCheckTime = moment(item.nextCheckTime)
						// 超时 一个月内提示
						if (nextCheckTime.isBefore(moment().add(1, "months"))) {
							return true
						}
					}
					return false
				})
				// 普通
				const arr = this.listData.filter(item => {
					if (
						Number.isNaN(item.nextCheckTime) &&
						!Number.isNaN(Date.parse(item.nextCheckTime))
					) {
						const nextCheckTime = moment(item.nextCheckTime)
						// 超时 一个月内提示
						if (nextCheckTime.isBefore(moment().add(1, "months"))) {
							return false
						}
					}
					return true
				})
				this.listData = [...redarr, ...arr]
			}
			this.loading = false
		},
		// 文件上传前
		beforeUpload(file) {
			formDataToMD5(file, md5Str => {
				this.extraData.md5 = md5Str
				// 获取md5后进行文件上传
				this.$refs.upload.post(file)
			})
			return false
		},
		// 文件上传成功后
		onSuccess(res) {
			if (res.code !== 0) {
				this.$Message.error(res.message)
			} else {
				this.$Message.success("导入成功！")
				this.initList()
			}
		},
		onError(error) {
			this.$Message.error(error)
		},
		// 搜索
		searchHandle() {
			this.searchParams = {
				...this.searchParams,
				startTime: this.times ? this.times[0] : "",
				endTime: this.times ? this.times[1] : ""
			}
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.times = []
			this.searchParams = {
				equipmentName: "",
				installSite: "",
				equipmentStatus: ""
			}
			this.searchHandle()
		},
		// 新增
		handleAddShow() {
			this.updateId = ""
			this.addVisible = true
		},
		// 导出
		async exportExcel() {
			const equipmentName = this.searchParams.equipmentName || ""
			const installSite = this.searchParams.installSite || ""
			const equipmentStatus = this.searchParams.equipmentStatus || ""
			const startTime = this.searchParams.startTime || ""
			const endTime = this.searchParams.endTime || ""
			const url = `${this.$baseUrl}/equipment/export?startTime=${startTime}&endTime=${endTime}&equipmentName=${equipmentName}&installSite=${installSite}&equipmentStatus=${equipmentStatus}&token=${this.$store.state.user.token}`
			window.open(url)
		},
		// 修改
		handleUpdateShow({ row }) {
			this.updateId = row.id
			this.addVisible = true
		},
		// 删除
		async handleSwitchState({ row }) {
			this.loading = true
			const res = await apiDelete({ id: row.id })
			if (res) {
				this.$Message.success("删除成功！")
				this.initList()
			}
			this.loading = false
		},
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 导入设备模板
		handleDownloadTemplate() {
			window.open(`${this.$baseUrl}/template/仪器设备信息模板.xlsx`)
		}
	}
}
</script>
